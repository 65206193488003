<template>
  <div id="f-book-now" style="width: calc(90vw - 20%) !important; padding: 0">
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="d-flex flex-column-fluid card">
      <div class="container-fluid">
        <div class="card-body mt-5">
          <!-- <div class="col-md-3">
            <h5>Select Archival</h5>

            <multiselect
              key="id"
              :options="getArchivals ? getArchivals : []"
              placeholder="Select Term "
              label="name"
              track-by="id"
              :preselect-first="false"
              v-model="selected_term"
              @select="changeDate"
            ></multiselect> -->
          <!-- <select
              v-model="archival_period"
              name=""
              class="form-control"
              id=""
            >
              <option
                v-for="(citem, index) in getArchivals"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select> -->
          <!-- </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for=""> Select Date</label>
              <input
                style="width: 120%;"
                type="date"
                v-model="getArchivalRenewDate"
                class="form-control"
                id="inputdate"
                name="inputdate"
              />
            </div>
          </div> -->

          <!-- <div v-if="show_term == '0'" class="col-lg-6">
            <h5>Select Date</h5>
            <vc-calendar
              :min-date="date"
              is-expanded
              :attributes="attrs"
              v-model="selectedDate"
              @dayclick="onDayClick"
            />
          </div> -->

          <f-payment
            :enrollData="appointmentData"
            :front_status="false"
            :is_both_card="false"
            :is_archival="true"
            :is_renew="true"
          ></f-payment>

          <div
            id="book-footer-temp"
            class="bottom-nav col-lg-12 col-md-12 col-12"
            style="
              position: fixed;
              margin-left: -4rem;

              bottom: 2rem;
              width: calc(90vw - 15%);
            "
          >
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="total_amount">
                    <label for="">
                      <small>Total</small>
                    </label>
                    <h3 class="footer-total-amount">
                      ${{ getTotalArchivalRateAmount | toFixedTwoDigit }}
                    </h3>
                  </div>
                  <button
                    class="btn btn-dark mr-2 mt-3 btn-right"
                    @click="goBack()"
                  >
                    Back
                  </button>
                  <button
                    class="btn btn-success mr-2 mt-3 btn-right"
                    @click="SubmitService()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer">
          <button
            class="btn btn-success mr-2 mt-3 btn-right"
            style="float: right"
            @click="submit()"
          >
            Submit
          </button>
          <button
            class="btn btn-dark mr-2 mt-3 btn-right"
            style="float: right"
            @click="goBack()"
          >
            Go Back
          </button>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import fBookNow from "@/pages/frontend/booknow/booknow.vue";
import fPayment from "@/pages/backend/appointment/archivepayment.vue";

import { mapGetters } from "vuex";
import {
  FETCH_NOTES,
  STORE_APPOINTMENT_ARCHIVE,
  CHECK_F_ABBREVIATION_CODE,
  FETCH_COUNTRIES,
  FETCH_F_CLIENT_ARCHIVAL_RATES,
  FETCH_SELF_APPOINMENT,
  FETCH_STATES,
} from "@/core/services/store/actions.type";
import {
  SET_ARCHIVAL_PERIOD_ID,
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  UPDATE_ABBREVIATION_CODE,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_RATE_TOTAL_AMOUNT,
} from "@/core/services/store/mutations.type";

export default {
  components: {
    fPayment,
  },
  computed: {
    ...mapGetters([
      "getServiceId",
      "getAddQty",
      "getfClientRate",
      "getTotalRateAmount",
      "getArchivalRates",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCity",
      "getPaymentCardPhonecode",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentType",
      "getIsHeartsPayment",
      "getTotalArchivalRateAmount",
      "getArchivalPeriodId",
    ]),
  },
  mounted() {
    var data = {
      token: this.$route.params.token,
      appointment_id: this.$route.params.app_id,
    };

    this.$store.dispatch(FETCH_SELF_APPOINMENT, data).then((res) => {
      this.appointmentData = res.data;
    });
    this.$store
      .dispatch(FETCH_F_CLIENT_ARCHIVAL_RATES, this.$route.params.client_id)
      .then((data) => {
        this.archivalData = data;
      });
    this.$store.commit(
      SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
      this.$route.params.onsite
    );
    this.$store.dispatch(FETCH_NOTES);
    this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
    this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, 0);
    this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
    this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);
    this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
    this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
    this.$store.commit(SET_PAYMENT_CARD_CITY, "");
    this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
    this.$store.commit(CARD_COUNTRY, "US");
    this.$store.commit(CARD_STATE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
    this.$store.dispatch(FETCH_COUNTRIES);
    this.$store.dispatch(FETCH_STATES);
  },
  data() {
    return {
      errorMsg: "",
      dataLoading: false,
      loadingMessage: "",
      saveDataLoader: false,
      appointmentData: {},
    };
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
  },
  methods: {
    goBack() {
      this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
      this.$router.push({
        name: "client.appointment.index",
        params: { client_slug: this.$route.params.client_slug },
      });
      // this.$router.go(-1);
    },
    checkAbbreviationCode() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, this.abree_code);
      if (this.updateAbbreviationCode != "") {
        this.abbreviation_code_error = true;

        this.$store
          .dispatch(CHECK_F_ABBREVIATION_CODE, {
            code: this.updateAbbreviationCode,
            client_slug: this.$route.params.client_slug,
          })
          .then((data) => {
            if (data.success) {
              this.paymentMethod = data.data.show_payment_option;
              if (!data.data.show_payment_option) {
                this.$store.commit(UPDATE_PAYMENT_TYPE, "A");
              }
              this.abree_code = "";
            } else {
              this.paymentMethod = true;
            }
          });
      } else {
        this.abbreviation_code_error = false;
      }
    },
    SubmitService() {
      if (this.getArchivalPeriodId != "") {
        var mytoken = document.getElementById("mytoken");

        mytoken = mytoken.innerHTML;
        if (this.updateAbbreviationCode != "") {
          if (!this.getAbbreviationCode.success) {
            this.$toastr.e(this.getAbbreviationCode.msg);
          } else {
            if (this.getPaymentType == "C") {
              this.loadingMessage =
                "please wait <br> While we process your payment...";

              if (mytoken == "" || mytoken == null) {
                this.$toastr.e(
                  "The card number entered is not compliant, please check and re-enter."
                );
              } else {
                if (this.getIsHeartsPayment) {
                  var expiremonth =
                    document.getElementById("expiremonth").innerHTML;
                  var expireyear =
                    document.getElementById("expireyear").innerHTML;

                  if (
                    mytoken == "" ||
                    mytoken == null ||
                    expiremonth == "" ||
                    expiremonth == null ||
                    expireyear == "" ||
                    expireyear == null
                    // this.getPaymentCardCvvNumber == ""
                  ) {
                    this.$toastr.e("Card info is required");
                  } else {
                    this.SubmitForm();
                  }
                } else {
                  if (
                    mytoken == "" ||
                    mytoken == null ||
                    this.getPaymentCardHolderName == "" ||
                    this.getPaymentCardExpiry == "" ||
                    this.getPaymentCardCvvNumber == ""
                  ) {
                    this.$toastr.e("Card info is required");
                  } else {
                    this.SubmitForm();
                  }
                }
              }
            } else {
              this.loadingMessage =
                "please wait <br> Your appointment is being scheduled...";

              this.SubmitForm();
            }
          }
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";
            if (mytoken == "" || mytoken == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth2 =
                  document.getElementById("expiremonth").innerHTML;
                var expireyear2 =
                  document.getElementById("expireyear").innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth2 == "" ||
                  expiremonth2 == null ||
                  expireyear2 == "" ||
                  expireyear2 == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being scheduled...";
            this.SubmitForm();
          }
        }
      } else {
        this.$toastr.e("Please select a archive option");
      }
    },
    reset() {
      this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, 0);
      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
      this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
      this.$store.commit(SET_PAYMENT_CARD_CITY, "");
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
      this.$store.commit(CARD_COUNTRY, "United States");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
    },

    SubmitForm() {
      this.errorMsg = "";
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      this.saveDataLoader = true;
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        appointment_id: this.$route.params.app_id,
        abbreviation_code: updateAbbreviationCode,
        sms_status: false,
        appt_token: this.$route.params.token,
        archival_period: this.getArchivalPeriodId,
      };

      let token = mytoken.innerHTML;
      if (this.getPaymentType == "C") {
        if (this.getIsHeartsPayment) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            // card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress,
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,

            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,
            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip,
          };
        }
      }

      this.$store
        .dispatch(STORE_APPOINTMENT_ARCHIVE, {
          formData,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toastr.s(res.msg);
            this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
            this.$router.go(-1);
          }
          this.saveDataLoader = false;
        })
        .catch((errs) => {
          if (errs.msg == "") {
            this.$toastr.e("oops something went wrong");
            return;
          }
          this.$toastr.e(errs.msg);
          this.errorMsg = errs.msg;
          this.saveDataLoader = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-danger-dim {
  background: #f7cece90 !important;
}
#book-footer-temp {
  .btn {
    height: 40px !important;
    float: right;
  }
  .total_amount {
    display: inline-block;
    .btn-right {
      float: right !important;
    }
  }
}
.alert-custom {
  /* width: 78%; */
  /* position: relative; */
  /* top: -15%; */
  /* left: 4%; */
  /* margin-left: 12%; */
  margin-top: 5%;
  z-index: 1;
  background: #dc3545 !important;
}
</style>
